import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Link as LinkMui, Typography, Tab, Tabs } from "@mui/material";
import {
  COLORS,
  FONT_WEIGHT,
  ICON_SIZE,
  LINE_HEIGHT,
  RADIUS,
  TEXTSIZE,
  WIDTH,
} from "@theme/constants";
import { ReactComponent as LinkIcon } from "@icons/link.svg";
import { ReactComponent as EditIcon } from "@icons/edit-white.svg";
import styled from "styled-components";
import { numberFormatThousand } from "@utils/commonFunction";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ModalComponent from "@components/modal/ModalComponent";
import useModalReducer, { MODAL_STATUS } from "@hooks/useModalReducer";
import { useMediaQuery } from "react-responsive";
import ImageAvatar from "@images/avatar.png";
import ImageDefault from "@images/default_image.jpg";
import BgDefaultMobile from "@images/bg_default_mobile.jpg";
import BgDefaultFold from "@images/bg_default_fold.jpg";

import { ReactComponent as MoreIcon } from "@icons/more.svg";
import { ReactComponent as SettingIcon } from "@icons/setting.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as PurchaseIcon } from "@icons/purchase_black.svg";
import ProfileContent from "../content/ProfileContent";

import { ReactComponent as TabFeedIcon } from "@icons/feed.svg";
import { ReactComponent as TabUnlockIcon } from "@icons/profile_unlock.svg";
import { ReactComponent as TabQuiz } from "@icons/quiz.svg";
import TruncateText from "../../../components/newComponents/truncate/TruncateText";

const Wrapper = styled.div`
  margin-bottom: 1rem;
  position: relative;

  .display-flex-row-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .display-flex-column-center {
    display: flex;

    justify-content: center;
    align-items: center;
  }

  .background-image {
    position: relative;
    height: 160px;
  }

  .background-image__first {
    background-color: ${COLORS.grey400};
    height: 100%;
    border-bottom-right-radius: ${RADIUS.superLarge};
  }

  .background-image__first__second {
    background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.7) 0.69%,
      rgba(0, 0, 0, 0) 40.28%,
      rgba(0, 0, 0, 0) 66.32%,
      rgba(0, 0, 0, 0.7) 100%
    );
    height: 100%;
    border-bottom-right-radius: ${RADIUS.superLarge};
  }

  .profile {
    // position: absolute;
    bottom: 0;
    display: flex;
    align-items: start;
    padding: 0 1rem;
    // height: 130px;
  }

  .profile__avatar {
    width: 100px;
    height: 100px;
    cursor: pointer;
  }

  .profile__nickname {
    color: ${COLORS.white};
    font-size: ${TEXTSIZE.large};
    font-weight: ${FONT_WEIGHT.xxLarge};
    line-height: ${LINE_HEIGHT.xxxxLarge};
    width: fit-content;
    // margin-bottom: 0.25rem;
  }

  .profile__introduce {
    // margin-bottom: 0.5rem;
    color: ${COLORS.grey200};
    font-size: ${TEXTSIZE.xSmall};
    font-weight: 500;
    line-height: ${LINE_HEIGHT.small};
    width: calc(100vw - 100px - 64px);
    max-width: calc(600px - 100px - 32px);
  }

  .profile__greeting {
    margin-top: 1rem;
    max-height: 70px;
    color: ${COLORS.grey500};
    font-size: ${TEXTSIZE.small};
    line-height: ${LINE_HEIGHT.xLarge};
    font-weight: 400;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }

  .count-number {
    font-size: ${TEXTSIZE.xxLarge};
    font-weight: ${FONT_WEIGHT.xxLarge};
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
  }

  .content-text {
    font-size: ${TEXTSIZE.xSmall};
    font-weight: ${FONT_WEIGHT.large};
    line-height: ${LINE_HEIGHT.small};
    text-align: center;
    color: ${COLORS.grey500};
  }

  .icon_plus {
    width: ${ICON_SIZE.small};
    height: ${ICON_SIZE.small};
    cursor: pointer;
  }

  .btn-action {
    width: 80px;
    height: 24px;
    padding: 4px, 12px, 4px, 12px;
    border-radius: ${RADIUS.large};
    margin: 0 0.25rem;
    color: ${COLORS.grey600};
    background: ${COLORS.grey50};
    :hover {
      color: ${COLORS.grey600};
      background: ${COLORS.grey50};
    }
  }

  .btn-action--active {
    color: ${COLORS.white};
    background: ${COLORS.primary4};
    :hover {
      color: ${COLORS.white};
      background: ${COLORS.primary4};
    }
  }

  .btn-action__text {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  .social-link {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    color: ${COLORS.primary4};
    margin-left: 0.5rem;
  }

  .profile__icon {
    height: 16px;
    width: 16px;
    cursor: pointer;
    margin-left: 4px;
  }
`;

const WrapperHeader = styled.div`
  position: absolute;
  z-index: 99;
  width: 100%;
  top: 0;

  .header {
    padding: 1rem 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .purchase-icon {
      path {
        fill: ${COLORS.primary4};
      }
    }
  }

  .header__icon {
    fill: ${COLORS.white};
    cursor: pointer;
  }

  .header__text {
    font-size: ${TEXTSIZE.large};
    font-weight: ${FONT_WEIGHT.large};
    line-height: ${LINE_HEIGHT.superLarge};
    color: ${COLORS.white};
  }
`;

const WrapperTab = styled.div`
  .MuiTabs-scroller,
  .MuiTabs-root,
  .MuiButtonBase-root {
    min-height: 100%;
  }

  .MuiTabs-flexContainer {
    align-items: center;
    justify-content: space-around;
  }

  .tab-wrap {
    background: ${COLORS.white};
    .MuiButtonBase-root {
      max-width: 100%;
      color: var(--subTextColor);
      flex: 1 0 auto;
      padding: 8px 0;

      &.Mui-selected {
        color: ${COLORS.primary4};
        .tab__icon {
          fill: ${COLORS.primary4};
        }
        .tab__icon {
          path {
            fill: ${COLORS.primary4};
          }
        }
        .tab-limited__icon {
          path {
            stroke: ${COLORS.primary4};
          }
        }
        .tab-quiz__icon {
          path {
            fill: ${COLORS.primary4};
          }
          // path:last-child {
          //   fill: ${COLORS.primary4};
          // }
        }
        .tab-unlock__icon {
          path {
            fill: ${COLORS.primary4};
          }
          circle {
            stroke: ${COLORS.primary4};
          }
        }
      }
      .tab__icon {
        fill: ${COLORS.grey500};
      }
    }

    .MuiTabs-indicator {
      height: 1px;
      background-color: ${COLORS.primary4};
    }
  }

  .tab-content__wrap {
    position: relative;

    .tab-content {
      height: 100%;
    }
  }
`;

const truncateStyle = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  // width: "calc(100% - 120px)",
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ProfileBackgroundComponent(props) {
  const {
    profileType,
    bgImage,
    nickname = "",
    profileImage,
    introduce = "",
    comment = "",
    outLinks = [],
    connectionsCount = 0,
    postCount = 0,
    myPic = 0,
    isConnecting,
    onClickPurchase,
    onClickFollow,
    onClickMessage,
    handleDrawer,
    userType,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { modalData, dispatchModal } = useModalReducer();

  const [tabList, setTabList] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const isMobileWithoutFold = useMediaQuery({
    query: `(max-width: ${WIDTH.fold})`,
  });

  const handleShowModalImage = (image) => {
    if (!image) return;

    dispatchModal({
      type: MODAL_STATUS.OPEN,
      data: {
        btnOKText: "",
        btnCancelText: "",
        title: "",
        subTitle: "",
        content: (
          <img
            src={image}
            style={{ width: "100%", height: "100%" }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = ImageDefault;
            }}
          />
        ),
      },
    });
  };

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    setSearchParams({ tab: newValue });
  };

  useEffect(() => {
    setActiveTab(Number(searchParams.get("tab")));
  }, [searchParams.get("tab")]);

  const otherTab = [
    {
      label: "myPost",
      text: <TabFeedIcon className={"tab__icon"} />,
      type: "profile-post",
      data: [],
    },
    {
      label: "postPaid",
      text: <TabUnlockIcon className={"tab-unlock__icon"} />,
      type: "post-paid",
      data: [],
    },
  ];

  const myTab = [
    {
      label: "myPost",
      text: <TabFeedIcon className={"tab__icon"} />,
      type: "profile-post",
      data: [],
    },

    {
      label: "myPaid",
      text: <TabUnlockIcon className={"tab-unlock__icon"} />,
      type: "my-paid",
      data: [],
    },
  ];

  useEffect(() => {
    if (profileType === "my") {
      const publicQuiz = {
        label: "myQuiz",
        text: <TabQuiz className={"tab-quiz__icon"} />,
        type: "quiz",
        data: [],
      };
      if (userType === 3) {
        myTab.splice(1, 0, publicQuiz);
      }
      setTabList(myTab);
    } else {
      const newOtherTab = [...otherTab];
      const publicQuiz = {
        label: "publicQuiz",
        text: <TabQuiz className={"tab-quiz__icon"} />,
        type: "quiz",
        data: [],
      };

      if (userType === 3) {
        newOtherTab.splice(1, 0, publicQuiz);
      }
      setTabList(newOtherTab);
    }
  }, [profileType, userType]);

  return (
    <>
      <Wrapper>
        <WrapperHeader>
          <Box className='header'>
            {/* <ArrowLeft className="header__icon" onClick={() => navigate(-1)} /> */}
            <Link
              to={"/profile/wallet"}
              style={{ visibility: profileType === "my" ? "visible" : "hidden" }}
            >
              <Box
                sx={{
                  display: "flex",
                  bgcolor: COLORS.grey100,
                  px: 1.5,
                  py: 0.5,
                  borderRadius: "18px",
                  gap: 0.25,
                }}
              >
                <PurchaseIcon className='purchase-icon' />
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "700",
                    lineHeight: "22px",
                  }}
                >
                  {numberFormatThousand(myPic)}
                </Typography>
              </Box>
            </Link>
            {profileType === "my" ? (
              <SettingIcon
                style={{ fill: COLORS.white, cursor: "pointer" }}
                onClick={() => navigate("/setting")}
              />
            ) : (
              <MoreIcon
                style={{ fill: COLORS.white, cursor: "pointer" }}
                onClick={() => handleDrawer(true, "otherUser")}
              />
            )}
          </Box>
        </WrapperHeader>
        {/* ProfileBackground */}
        <Box className='background-image'>
          <Box
            className='background-image__first'
            style={{
              backgroundImage: `url(${bgImage ||  (isMobileWithoutFold ? BgDefaultMobile : BgDefaultFold)})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              cursor: "pointer",
            }}
            onClick={() => handleShowModalImage(bgImage)}
          >
            <Box className='background-image__first__second'></Box>
          </Box>
        </Box>

        <Box sx={{ position: "relative", mt: "-50px", zIndex: 2 }}>
          <Box sx={{ display: "flex", flexDirection: `${isMobileWithoutFold ? "column" : "row"}` }}>
            <Box className='profile' sx={{ width: isMobileWithoutFold ? "100%" : "50%", gap: 2 }}>
              <Avatar
                className='profile__avatar'
                // alt={nickname}
                src={profileImage}
                onClick={() => handleShowModalImage(profileImage)}
              >
                <img className='MuiAvatar-img' src={ImageAvatar} />
              </Avatar>
              <Box
                sx={{
                  width: "calc(100% - 132px)",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography className='profile__nickname' sx={truncateStyle}>
                    {nickname}
                  </Typography>
                  {profileType == "my" && (
                    <EditIcon
                      className='profile__icon profile__icon--edit'
                      onClick={() => navigate("/profile/edit/")}
                    />
                  )}
                </Box>
                <Typography className='profile__introduce' sx={truncateStyle}>
                  {introduce ? introduce : "자기소개를 입력해주세요."}
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <TruncateText
                    content={comment ? comment : "프로필 인사말을 입력해주세요."}
                    lines={3}
                    styleContent={{
                      color: COLORS.grey500,
                      fontSize: TEXTSIZE.small,
                      lineHeight: LINE_HEIGHT.xLarge,
                      fontWeight: 400,
                    }}
                  />
                </Box>
              </Box>
            </Box>
            {!isMobileWithoutFold && (
              <>
                <Box
                  sx={{
                    mt: "58px",
                    width: "50%",
                    right: "0",
                    borderLeft: `1px solid ${COLORS.grey50}`,
                  }}
                >
                  <Box className='display-flex-row-center'>
                    <Link to={`/profile/connection/${nickname}`}>
                      <Box sx={{ px: 1 }}>
                        <Typography className='count-number'>
                          {numberFormatThousand(connectionsCount) || 0}
                        </Typography>
                        <Typography className='content-text'>Connections</Typography>
                      </Box>
                    </Link>
                    <Box sx={{ px: 1 }}>
                      <Typography className='count-number'>
                        {numberFormatThousand(postCount) || 0}
                      </Typography>
                      <Typography className='content-text'>Contents</Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            )}

            {outLinks?.length > 0 && isMobileWithoutFold && (
              <Box sx={{ mb: 1 }}>
                {outLinks.map((link, index) => (
                  <Box className='display-flex-row-center' key={link + index}>
                    <LinkIcon />
                    <LinkMui
                      href={link.includes("https://") ? link : `https://${link}`}
                      target='_blank'
                      className='social-link'
                      sx={{
                        ...truncateStyle,
                        maxWidth: "50%",
                        cursor: "pointer",
                        width: "auto",
                      }}
                    >
                      {link}
                    </LinkMui>
                  </Box>
                ))}
              </Box>
            )}

            {/* conections */}
            {isMobileWithoutFold && (
              <Box className='display-flex-row-center'>
                <Link to={`/profile/connection/${nickname}`}>
                  <Box sx={{ px: 1 }}>
                    <Typography className='count-number'>
                      {numberFormatThousand(connectionsCount) || 0}
                    </Typography>
                    <Typography className='content-text'>Connections</Typography>
                  </Box>
                </Link>
                <Box sx={{ px: 1 }}>
                  <Typography className='count-number'>
                    {numberFormatThousand(postCount) || 0}
                  </Typography>
                  <Typography className='content-text'>Contents</Typography>
                </Box>
              </Box>
            )}
          </Box>
          {outLinks?.length > 0 && !isMobileWithoutFold && (
            <Box sx={{ mt: 1, mb: 1, ml: "132px" }}>
              {outLinks.map((link, index) => (
                <Box sx={{ display: "flex" }} key={link + index}>
                  <LinkIcon />
                  <LinkMui
                    href={link.includes("https://") ? link : `https://${link}`}
                    target='_blank'
                    className='social-link'
                    sx={{
                      ...truncateStyle,
                      maxWidth: "50%",
                      cursor: "pointer",
                      width: "auto",
                    }}
                  >
                    {link}
                  </LinkMui>
                </Box>
              ))}
            </Box>
          )}
          {/* action */}
          {profileType != "my" && (
            <Box
              // className="display-flex-row-center"
              sx={{
                py: "0.75rem",
                display: "flex",
                alignItems: "center",
                justifyContent: isMobileWithoutFold ? "center" : "start",
                ml: isMobileWithoutFold ? "0px" : "132px",
              }}
            >
              <Button
                className={`btn-action " ${!isConnecting && "btn-action--active"}`}
                onClick={onClickFollow}
              >
                <Typography className='btn-action__text'>
                  {isConnecting ? t("button.connecting") : t("button.connect")}
                </Typography>
              </Button>
              <Button className='btn-action' onClick={onClickMessage}>
                {t("button.message")}
              </Button>
            </Box>
          )}
          <Box sx={{ position: "sticky", top: "56px", zIndex: "10", mt: isMobileWithoutFold ? 1 : 3 }}>
            <WrapperTab>
              <Tabs
                value={activeTab}
                onChange={handleChange}
                aria-label='tabs'
                className='tab-wrap'
              >
                {tabList.map((item, index) => (
                  <Tab
                    data-label={item.label}
                    label={item.text}
                    {...a11yProps(index)}
                    key={index}
                  />
                ))}
              </Tabs>
            </WrapperTab>
          </Box>

          {tabList.length > 0 && (
            <ProfileContent
              tabList={tabList}
              profileType={profileType}
              activeTab={activeTab}
              searchParams={searchParams}
            />
          )}
        </Box>
        <ModalComponent
          isCloseIcon={true}
          open={modalData.open}
          modalData={modalData}
          handleClose={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
          handleOk={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
          handleCancel={() => dispatchModal({ type: MODAL_STATUS.CLOSE })}
          btnOKProps={{}}
          btnCancelProps={{}}
          dialogProps={{
            sx: {
              padding: "20px",
              "& .MuiDialogContent-root": {
                py: 2,
                px: 2,
              },
            },
          }}
        />
      </Wrapper>
    </>
  );
}

export default ProfileBackgroundComponent;
