import { Box, Grid } from "@mui/material";

const footers = [
  {
    label: (
      <Box sx={{ display: "flex", gap: "16px" }}>
        <p style={{ whiteSpace: "pre-line" }}>주식회사 엑스투비</p>
      </Box>
    ),
    link: "",
  },
  {
    label: (
      <Box>
        <p style={{ marginBottom: "8px" }}>대표 박수현</p>
        <p>Email z@x2b.biz</p>
      </Box>
    ),
    link: "",
  },
  {
    label: (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <p>사업자등록번호 848-81-03048</p>
        <p>주소 서울특별시 서초구 서초대로64길,8 4층(서초동)</p>
      </Box>
    ),
    link: "",
  },
  {
    label: (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <p>고객센터 070-7798-1644-7012</p>
        <p>평일 월-금 10:00~18:00</p>
      </Box>
    ),
    link: "",
  },
];

const FooterPaymentGateway = () => {
  return (
    <footer>
      <div
        style={{
          background: "#020220",
          padding: "32px 20px",
          fontSize: "12px",
          lineHeight: "18px",
        }}
      >
        <div
          style={{
            padding: "16px 0px",
            marginBottom: "16px",
            borderTop: "1px solid #3B3B3B",
            borderBottom: "1px solid #3B3B3B",
            color: "#C0C0C0",
          }}
        >
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, md: 1 }}>
            {footers.map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
                // eslint-disable-next-line no-nested-ternary
                sm={index === 0 ? 2 : index === 2 ? 4 : 3}
              >
                {item?.label}
              </Grid>
            ))}
          </Grid>
        </div>
        <p
          style={{
            color: "#555555",
          }}
        >
          Copyright .2024 X2B LTD. All rights reserved
        </p>
      </div>
    </footer>
  );
};

export default FooterPaymentGateway;
